
import BaseInfoContent from '~/components/base/BaseInfoContent.vue';
import { EventBus } from '~/components/eventBus/EventBus';
import FilterMixinVue from '~/components/market/base/FilterMixin.vue';
export default {
  components: { BaseInfoContent },
  mixins: [FilterMixinVue],
  props: {},
  data() {
    return {
      last: undefined,
      hasMoreItems: false,
      itemsData: [],
    };
  },
  fetchOnServer: false,
  async fetch() {
    const data = await this.$api.market.getOfferings({
      last: this.last,
      status: ['closed', 'nobids'],
    });
    await this.handleOfferingsData(data);
    EventBus.$emit('backend-pagination-fetched', this.hasMoreItems);
  },
  methods: {
    async updateOfferings(openOfferings) {
      for (const id of openOfferings) {
        if (
          !this.itemsData.some(
            (offering) => offering.groups[0].offeringid === id,
          )
        ) {
          const newOffering = await this.$api.market.getOneMarketItem({
            id,
          });
          if (newOffering !== false) this.itemsData.unshift(newOffering);
        }
      }
    },
    async updateOffering(id) {
      if (
        this.itemsData.some((offering) => offering.groups[0].offeringid === id)
      ) {
        const updatedOffering = await this.$api.market.getOneMarketItem({
          id,
        });
        if (updatedOffering !== false)
          this.itemsData = this.itemsData.map((offering) => {
            if (offering.groups[0].offeringid === id) return updatedOffering;
            return offering;
          });
        else
          this.itemsData = this.itemsData.filter(
            (offering) => offering.groups[0].offeringid !== id,
          );
      }
    },
  },
  mounted() {
    EventBus.$on('update-offerings', (offerings) =>
      this.updateOfferings(offerings),
    );
    EventBus.$on('backend-pagination-fetch', () => this.$fetch());
    EventBus.$on('update-offering', ({ id }) => this.updateOffering(id));
  },
  beforeDestroy() {
    EventBus.$off('update-offerings');
    EventBus.$off('backend-pagination-fetch');
    EventBus.$off('update-offering');
  },
};
