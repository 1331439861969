
/*
   mixin to handle pagination of elements, to prevent load too many components.
   To make it works is needed "listToPaginate(array)" in data on merged component and
    <client-only>
        <infinite-loading
            direction="bottom"
            @infinite="infiniteHandler"
            :identifier="infiniteHandlerKey"
        >
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
    </client-only>
    Loaded in the merged component.
*/
import { randomString } from '~/utils/functions';
import { PAGINATION_LIMIT } from '~/vars/api';
import { EventBus } from '~/components/eventBus/EventBus';
export default {
  data: () => ({
    page: 1,
    infiniteHandlerKey: '',
    loadingPaginationData: false,
    sortedListToPaginate: [],
  }),
  computed: {
    totalElementsToShow() {
      const totalElementsToShow = PAGINATION_LIMIT;
      return this.page * totalElementsToShow;
    },
    elementsToShow() {
      return [...this.listToPaginate].splice(0, this.totalElementsToShow);
    },
  },
  watch: {
    listToPaginate(value) {
      if (!this.loadingPaginationData) {
        this.infiniteHandlerKey = randomString(5);
      }
      if (
        value &&
        Math.ceil(value.length / PAGINATION_LIMIT) > this.page &&
        !this.hasMoreItems
      )
        EventBus.$emit('backend-pagination-fetch');
    },
  },
  mounted() {},
  beforeDestroy() {
    EventBus.$off('backend-pagination-fetched');
  },
  methods: {
    infiniteHandler($state) {
      if (!this.loadingPaginationData) {
        this.loadingPaginationData = true;
        this.handleWithPromise($state);
      }
    },
    handleWithPromise($state) {
      if (
        this.hasMoreItems !== false ||
        (this.listToPaginate &&
          this.elementsToShow.length >= PAGINATION_LIMIT &&
          this.listToPaginate.length === this.elementsToShow.length)
      ) {
        EventBus.$on('backend-pagination-fetched', () => {
          this.loadingPaginationData = false;
          if (
            Math.ceil(this.listToPaginate.length / PAGINATION_LIMIT) > this.page
          ) {
            this.page++;
          }
          if (this.hasMoreItems) {
            this.$nextTick(() => {
              $state.loaded();
            });
          } else {
            $state.complete();
          }
        });
        EventBus.$emit('backend-pagination-fetch');
      } else {
        if (
          this.listToPaginate &&
          this.listToPaginate.length > this.elementsToShow.length
        ) {
          this.page++;
          this.$nextTick(() => {
            $state.loaded();
          });
        } else {
          $state.complete();
        }
        this.loadingPaginationData = false;
      }
    },
  },
};
