
import BackendPaginateMixinHandlerVue from '../commons/BackendPaginateMixinHandler.vue';
import ProductCardNew from './base/ProductCardNew.vue';
export default {
  components: { ProductCardNew },
  mixins: [BackendPaginateMixinHandlerVue],
  props: {
    offerings: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasMoreItems: {
      type: Boolean,
      default: null,
    },
    // title: {
    //   type: String,
    //   default: '',
    // },
    // subtitle: {
    //   type: String,
    //   default: '',
    // },
  },
  computed: {
    colsBreakpoints() {
      return { lg: 3, md: 4, sm: 6, xs: 12 };
    },
    listToPaginate() {
      return this.offerings;
    },
  },
};
