
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
  },
};
