
import { GROUP_TYPE_AVAILABLE, PAGINATION_LIMIT } from '~/vars/api';
import {
  MARKET_FILTERS_DEFAULT,
  MY_OFFERINGS_BIDS_FILTERS_DEFAULT,
} from '~/vars/market';
export default {
  props: {
    filterApplied: {
      type: Boolean,
      default: false,
    },
    filterByWords: {
      type: String,
      default: '',
    },
    appliedFilters: {
      type: Object,
    },
    teams: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      functions: {
        access: this.meetAccess,
        categories: this.meetCategories,
        offer: this.meetOffer,
        type: this.meetType,
        creation: this.meetCreation,
        status: this.meetStatus,
        teams: this.meetTeam,
      },
    };
  },
  watch: {
    appliedFilters(value) {
      if (this.itemsToShow.length <= PAGINATION_LIMIT) this.$fetch();
    },
    filterByWords(newValue, oldValue) {
      if (this.itemsToShow.length <= PAGINATION_LIMIT && oldValue.length === 0)
        this.$fetch();
    },
  },

  computed: {
    tabs() {
      return ['marketplace', 'myofferings', 'mybids', 'collections'];
    },
    currentTab() {
      return this.tabs.includes(this.$route.path.split('/')[3])
        ? this.$route.path.split('/')[3]
        : '';
    },
    activeFiltersByTab() {
      switch (this.currentTab) {
        case 'marketplace':
          if (!this.$route.query.category) return MARKET_FILTERS_DEFAULT;
          else {
            const filters = Object.assign({}, MARKET_FILTERS_DEFAULT);
            return Object.assign(filters, {
              categories: [this.$route.query.category],
            });
          }
        case 'myofferings':
          return MY_OFFERINGS_BIDS_FILTERS_DEFAULT;
        case 'mybids':
          return MY_OFFERINGS_BIDS_FILTERS_DEFAULT;
        default: {
        }
      }
    },
    filtered() {
      return this.filterApplied ? this.$t('market.base.search') : '';
    },
    itemsToShow() {
      let filteredItems;
      if (this.currentTab.includes('collections')) {
        filteredItems = this.itemsData;
      } else {
        filteredItems = this.itemsData
          .filter(
            (offering) =>
              (['marketplace'].includes(this.currentTab) &&
                offering.groups[0].nftlist.length > 0) ||
              !['marketplace'].includes(this.currentTab),
          )
          .sort(
            (a, b) =>
              b.groups[0].offering.startdatestamp -
              a.groups[0].offering.startdatestamp,
          )
          .sort((a, b) => {
            if (
              ['open', 'nobids'].includes(b.groups[0].offering.status) &&
              a.groups[0].offering.status === 'closed'
            )
              return -1;
            if (
              ['open', 'nobids'].includes(a.groups[0].offering.status) &&
              b.groups[0].offering.status === 'closed'
            )
              return 1;
            else return 0;
          });
      }

      if (this.filterByWords.length) {
        filteredItems = this.onFilterByWordsChanged(filteredItems);
      }
      if (!this.currentTab.includes('collections'))
        filteredItems = this.onFilterByTagApplied(filteredItems);

      return filteredItems;
    },
  },
  methods: {
    handleOfferingsData(data) {
      if (typeof this.last === 'undefined' && this.itemsData.length === 0) {
        this.itemsData = data.items;
      } else {
        this.itemsData.push(
          ...data.items.filter(
            (item) =>
              !this.itemsData.some(
                (item2) =>
                  item2.groups[0].offeringid === item.groups[0].offeringid,
              ),
          ),
        );
      }
      if (
        typeof this.last === 'undefined' ||
        (typeof this.last !== 'undefined' && this.last !== data.last)
      ) {
        this.last =
          typeof data.last === 'undefined'
            ? data.items[data.items.length - 1]
            : data.last;
        this.hasMoreItems = data.items.length === PAGINATION_LIMIT;
      } else {
        this.hasMoreItems = false;
      }
    },
    onFilterByWordsChanged(filteredProducts) {
      const words = this.filterByWords.toLocaleLowerCase();
      if (words.length > 0) {
        return filteredProducts.reduce((filtered, offering) => {
          const owner =
            offering.groups[0].type === GROUP_TYPE_AVAILABLE
              ? this.$auth.user.data.nick
              : offering.groups[0].offering.ownernick;
          const creator = offering.groups[0].offering.issuernick;
          if (
            offering.contract.name.toLocaleLowerCase().includes(words) ||
            owner.toLocaleLowerCase().includes(words) ||
            // owner.name.toLocaleLowerCase().includes(words) ||
            // owner.surname.toLocaleLowerCase().includes(words) ||
            (creator && creator.toLocaleLowerCase().includes(words)) ||
            // creator.name.toLocaleLowerCase().includes(words) ||
            // creator.surname.toLocaleLowerCase().includes(words) ||
            (offering.tags &&
              offering.tags.some((tag) =>
                tag.toLocaleLowerCase().includes(words),
              ))
          )
            // (price && price === words))
            filtered.push(offering);
          return filtered;
        }, []);
      } else {
        return filteredProducts;
      }
    },
    onFilterByTagApplied(filteredProducts) {
      return filteredProducts.filter((offering) => {
        return Object.keys(this.appliedFilters).reduce((resolve, key) => {
          resolve = this.meetFilter(key, offering) && resolve;
          return resolve;
        }, true);
      });
    },
    meetFilter(key, offering) {
      if (
        this.appliedFilters[key].some((filter) => filter === 'any') ||
        this.functions[key](key, offering)
      )
        return true;
      return false;
    },
    meetCategories(key, offering) {
      const result = this.appliedFilters[key].reduce((resolve, item) => {
        if (
          (offering.groups[0].offering.category &&
            offering.groups[0].offering.category === item) ||
          (offering.groups[0].offering.categories &&
            offering.groups[0].offering.categories.some(
              (category) => category === item,
            ))
        ) {
          resolve = true;
        }
        return resolve;
      }, false);
      return result;
    },
    meetTeam(key, offering) {
      let result;
      if (this.appliedFilters[key].some((filter) => filter === 'following')) {
        const followingOwners = this.teams.reduce((list, team) => {
          if (team.following === true) list.push(team.nick);
          return list;
        }, []);
        result = followingOwners.reduce((resolve, item) => {
          if (offering.groups[0].offering.ownernick === item) resolve = true;
          return resolve;
        }, false);
      } else
        result = this.appliedFilters[key].reduce((resolve, item) => {
          if (offering.groups[0].offering.ownernick === item) resolve = true;
          return resolve;
        }, false);
      return result;
    },
    meetStatus(key, offering) {
      return this.appliedFilters[key].some((item) => {
        const offeringStatus = offering.groups[0].offering.status;
        if (
          (['closed', 'sold'].includes(item) && offeringStatus === 'closed') ||
          (!['closed', 'sold'].includes(item) && offeringStatus !== 'closed')
        )
          return true;
        return false;
      });
    },
    meetAccess(key, offering) {
      return this.appliedFilters[key].some(
        (item) => item === offering.groups[0].access,
      );
    },
    meetType(key, offering) {
      return this.appliedFilters[key].some(
        (item) =>
          (item === 'product_physical' &&
            offering.contract.product_type &&
            offering.contract.product_type === 'physical') ||
          (item === 'product_digital' &&
            offering.contract.product_type &&
            offering.contract.product_type === 'digital') ||
          (item === 'ticket' && offering.contract.is_ticket === true) ||
          (item === 'service' &&
            !offering.contract.product_type &&
            offering.contract.is_ticket !== true),
      );
    },
    meetOffer(key, offering) {
      return this.appliedFilters[key].some(
        (item) =>
          (item === 'auction' && offering.groups[0].offering.allowbid) ||
          (item === 'buy' && offering.groups[0].offering.allowbuy),
      );
    },
    meetCreation(key, offering) {
      return this.appliedFilters[key].some(
        (item) =>
          (item === 'mycreation' &&
            offering.groups[0].contract.issuernick ===
              this.$auth.user.data.nick) ||
          (item === 'other' &&
            offering.groups[0].contract.issuernick !==
              this.$auth.user.data.nick),
      );
    },
  },
};
